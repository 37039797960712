<template>
  <div>
    <v-card>
      <v-toolbar color="accent" fixed>
        <v-toolbar-title>
          <v-icon left>mdi-file-import</v-icon>
          {{ $vuetify.lang.t("$vuetify.action.upload_file") }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-title class="headline"></v-card-title>
      <v-card-text>
        <v-file-input
          show-size
          :placeholder="$vuetify.lang.t('$vuetify.action.choose_file')"
          ref="file"
          v-model="newFile"
          :loading="loading"
        ></v-file-input>
      </v-card-text>
      <v-card-actions v-if="newFile">
        <v-spacer></v-spacer>
        <v-btn @click="upload()">
          <v-icon class="mr-3">mdi-content-save</v-icon>
          {{ $vuetify.lang.t("$vuetify.action.upload") }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <br /><br />
    {{ $vuetify.lang.t("$vuetify.site.uploaded_files") }}
    <br /><br />
    <hr />
    <br />

    <div v-if="items.files">
      <template v-for="(item, index) in items.files">
        <div class="row ml-1 mr-1" :key="index" v-if="!item.delete">
          <div class="col col-md-6">{{ item.filename }}</div>
          <div class="col col-md-5">{{ item.url }}</div>
          <div class="col col-md-1">
            <v-btn @click="showRemoveFilePopup(index)"
              ><v-icon>mdi-delete</v-icon></v-btn
            >
          </div>
        </div>
      </template>
    </div>

    <RemoveFile :removeFilePopup="removeFilePopup" :fileIndex=fileIndex @closeRemoveFilePopup="closeRemoveFilePopup"/>
  </div>
</template>

<script>
import Api from "@/services/api";
import RemoveFile from "@/components/manager/popup/RemoveFile";
export default {
  components: { RemoveFile },
  data() {
    return {
      items: false,
      data: this.getData(),
      newFile: null,
      loading: false,
      removeFilePopup: false,
      fileIndex: null
    };
  },
  watch: {
    $route() {
      this.getData();
    },
  },
  methods: {
    getData() {
      Api.settings("get").then(({ data }) => {
        this.items = data;
      });
      this.saveItemsLoading = false;
    },
    upload() {
      this.loading = true;
      let f = this.$refs["file"].$refs.input.files[0];
      let formData = new FormData();
      formData.append("file", f);
      Api.media("upload", formData).then(({ data }) => {
        this.items.files.push(data);
        this.newFile = null;

        this.save();
      });
    },
    save() {
      this.loading = true;

      Api.settings("set", this.items).then(() => {
        this.$toast.ok(this.$vuetify.lang.t("$vuetify.action.update_success"));
        this.loading = false;
      });
    },
    showRemoveFilePopup(fileIndex) {
      this.removeFilePopup = true;
      this.fileIndex = fileIndex;
    },
    closeRemoveFilePopup(fileIndex) {
      this.removeFilePopup = false
      if(fileIndex === null) {
        return;
      }

      this.items.files[fileIndex]['delete'] = true;
      this.save();
    }
  },
};
</script>
<style scoped>
.row:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>